<template>
  <div class="ma-4" style="height:100%">
    <admin-header></admin-header>
    <v-tabs v-model="tab">
      <v-tab>Dates</v-tab>
      <v-tab>Instructors/Accompanists</v-tab>
      <v-tab>Instruments/Ratings</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card>
          <v-card-text>
            <p>Term End Date: {{ termEndDate }}</p>
            <p>Reading Days: {{ readingStart }} - {{ readingEnd }}</p>
            <p>Finals: {{ finalsStart }} - {{ finalsEnd }}</p>
            <date-picker v-model="openDate" label="Sign-Ups Open Date" outlined hide-details />
            <p class="mt-4">Slots will be created for each date for 10am-12pm and 1pm-3pm.</p>
          </v-card-text>
          <v-list v-if="days.length > 0">
            <v-list-item v-for="date in days" :key="date">
              <v-list-item-title>{{ stringFormatDate(date, true, true) }}</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-alert v-else type="error">No dates have been set up</v-alert>
          <v-card-actions>
            <v-dialog v-model="addDateDialog" width="400">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text>Add Date</v-btn>
              </template>
              <v-card>
                <v-card-title>Add Jury Date</v-card-title>
                <v-card-text>
                  <p>This will add slots for this date in the time period of 10am-12pm and 1pm-3pm. You can inactivate individual slots on the Jury Slots page if you want a different period.</p>
                  <date-picker v-model="dateToAdd" :min-date="minDate" :max-date="maxDate" label="Add Date" outlined hide-details />
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="addDateDialog = false">Cancel</v-btn>
                  <v-btn :disabled="isAdding" color="success" text @click="addDate()">{{ isAdding ? 'Adding Slots...' : 'Add Date' }}</v-btn>
                </v-card-actions>
                <v-progress-linear v-if="isAdding" :value="addProgress" />
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <setup-people :id="id" :days="days" />
      </v-tab-item>
      <v-tab-item>
        <setup-instruments :id="id" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  components: {
    AdminHeader: () => import('@/components/student/music/admin/header'),
    DatePicker: () => import('@/components/forms/inputs/DatePicker'),
    SetupPeople: () => import('@/components/student/music/admin/setupPeople'),
    SetupInstruments: () => import('@/components/student/music/admin/setupInstruments')
  },
  setup (props, { root }) {
    const setupService = root.$feathers.service('student/music/jury-setup')
    const slotService = root.$feathers.service('student/music/jury-slot')
    const term = computed({
      get: () => root.$store.state.student.music.admin.term,
      set: (term) => root.$store.commit('student/setMusicAdmin', { term })
    })
    const tab = ref(0)
    const isLoading = ref(true)
    const id = ref('')
    const termEndDate = ref('')
    const readingStart = ref('')
    const readingEnd = ref('')
    const finalsStart = ref('')
    const finalsEnd = ref('')
    const minDate = ref('')
    const maxDate = ref('')
    const openDate = ref('')
    watch(openDate, () => {
      if (openDate.value !== '' && id.value !== '' && !isLoading.value) {
        const open = new Date(openDate.value + ' 8:00 AM')
        // open.setHours(8)
        // open.setMinutes(0)
        // open.setSeconds(0)
        // if (open.toISOString().substring(0, 10) !== openDate.value) {
        //   open.setDate(open.getDate() + 1)
        // }
        setupService.patch(id.value, { open }).catch((e) => {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error updating open date: ' + e })
        })
      }
    })
    const days = ref([])
    const addDateDialog = ref(false)
    const dateToAdd = ref('')
    const isAdding = ref(false)
    const addProgress = ref(0)
    async function addDate () {
      const dt = new Date(dateToAdd.value)
      dt.setHours(10)
      if (dt.toISOString().substring(0, 10) !== dateToAdd.value) {
        dt.setDate(dateToAdd.value.substring(8, 10))
      }
      if (days.value.includes(dt.toISOString())) {
        alert('The selected date has already been added')
        return
      }
      addProgress.value = 0
      isAdding.value = true
      const rec = await setupService.patch(id.value, { $push: { days: dt } })
      // Create the slots for this date
      const start = new Date(dt)
      for (let i = 0; i < 24; i++) {
        if (start.getHours() === 12) start.setHours(13)
        const end = new Date(start)
        end.setMinutes(end.getMinutes() + 10)
        await slotService.create({ term: term.value, start, end })
        start.setMinutes(start.getMinutes() + 10)
        addProgress.value = ((i + 1) / 24) * 100
      }
      setTimeout(() => {
        days.value = rec.days
        addDateDialog.value = false
        isAdding.value = false
      }, 500)
    }

    watch(term, async () => {
      if (term.value === '') return
      root.$feathers.service('system/term').find({ query: { term: term.value } }).then(({ data }) => {
        if (data.length > 0) {
          const { end, closures } = data[0]
          termEndDate.value = stringFormatDate(end, true, true)
          const reading = closures.find(({ label }) => label.substring(0, 7) === 'Reading')
          if (reading) {
            minDate.value = reading.start.substring(0, 10)
            readingStart.value = stringFormatDate(reading.start, true, true)
            readingEnd.value = stringFormatDate(reading.end, true, true)
          }
          const finals = closures.find(({ label }) => label.substring(0, 5) === 'Final')
          if (finals) {
            maxDate.value = finals.end.substring(0, 10)
            finalsStart.value = stringFormatDate(finals.start, true, true)
            finalsEnd.value = stringFormatDate(finals.end, true, true)
          }
        }
      })
      const { data } = await setupService.find({ query: { term: term.value } })
      if (data.length > 0) {
        id.value = data[0]._id
        openDate.value = 'open' in data[0] && data[0].open != null && data[0].open !== '' ? data[0].open.substring(0, 10) : ''
        days.value = data[0].days
        days.value.sort()
        instruments.value = data[0].instruments
      } else {
        const instr = ['Piano', 'Organ', 'Voice', 'Violin', 'Viola', 'Cello', 'String Bass', 'Guitar', 'Jazz Piano', 'Trumpet', 'French Horn', 'Trombone', 'Euphonium', 'Tuba', 'Flute', 'Clarinet', 'Oboe', 'English Horn', 'Bass Clarinet', 'Bassoon', 'Percussion']
        const obj = { term: term.value, ratings: ['Presentation', 'Preparation', 'Persona', 'Progress', 'Potential'], instruments: instr.map((name) => { return { name } }) }
        const rec = await setupService.create(obj)
        id.value = rec._id
        days.value = []
        instruments.value = []
      }
      setTimeout(() => { isLoading.value = false }, 1000)
    })

    const instruments = ref([])

    return {
      term,
      tab,
      id,
      termEndDate,
      readingStart,
      readingEnd,
      finalsStart,
      finalsEnd,
      minDate,
      maxDate,
      openDate,
      days,
      addDateDialog,
      dateToAdd,
      isAdding,
      addProgress,
      addDate,
      instruments,
      stringFormatDate
    }
  }
}
</script>
